var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Work Order" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.formRules.woDate.label
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.woDate
                                                        .decorator,
                                                    expression:
                                                      "formRules.woDate.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  placeholder:
                                                    _vm.formRules.woDate
                                                      .placeholder
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.formRules.customerName
                                                    .label
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .customerName
                                                          .decorator,
                                                      expression:
                                                        "formRules.customerName.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.customerName
                                                        .name,
                                                    placeholder:
                                                      _vm.formRules.customerName
                                                        .placeholder,
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loadingDatalistCustomerName,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return this$1.getListContact(
                                                        value
                                                      )
                                                    },
                                                    change: function(value) {
                                                      return _vm.changeContact(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.datalistCustomerName,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.firstName
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.firstName
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.formRules.customerAddress
                                                    .label
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .customerAddress
                                                          .decorator,
                                                      expression:
                                                        "formRules.customerAddress.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules
                                                        .customerAddress.name,
                                                    placeholder:
                                                      _vm.formRules
                                                        .customerAddress
                                                        .placeholder,
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loadingDatalistCustomerAddress,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    change: function(value) {
                                                      return _vm.changeContactAddress(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.datalistCustomerAddress,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.address
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.address
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.address
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.formRules.picName.label
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.picName
                                                        .decorator,
                                                    expression:
                                                      "formRules.picName.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.picName.name,
                                                  placeholder:
                                                    _vm.formRules.picName
                                                      .placeholder,
                                                  autocomplete: "off"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.formRules.mechanicName
                                                    .label
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .mechanicName
                                                          .decorator,
                                                      expression:
                                                        "formRules.mechanicName.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.mechanicName
                                                        .name,
                                                    placeholder:
                                                      _vm.formRules.mechanicName
                                                        .placeholder,
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loadingDatalistCustomerMechanic,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return this$1.getListContactMechanic(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.datalistCustomerMechanic,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.firstName
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.firstName
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.formRules.scheduleOrder
                                                    .label
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .scheduleOrder
                                                        .decorator,
                                                    expression:
                                                      "formRules.scheduleOrder.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  placeholder:
                                                    _vm.formRules.scheduleOrder
                                                      .placeholder
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.formRules.description
                                                    .label
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.description
                                                        .decorator,
                                                    expression:
                                                      "formRules.description.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.description
                                                      .name,
                                                  placeholder:
                                                    _vm.formRules.description
                                                      .placeholder,
                                                  autocomplete: "off"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("a-col", { attrs: { span: 24 } }, [
                            _c("h3", [_vm._v("Items Detail")])
                          ]),
                          _c(
                            "a-col",
                            { staticClass: "mt-3", attrs: { span: 24 } },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "a-tabs",
                                        { attrs: { type: "card" } },
                                        [
                                          _c(
                                            "a-tab-pane",
                                            {
                                              key: "Units",
                                              staticClass: "bordertabs p-4",
                                              attrs: { tab: "Units" }
                                            },
                                            [
                                              _c("TableCustom", {
                                                attrs: {
                                                  idtable: "table1",
                                                  dataSource: _vm.dataListUnits,
                                                  columns:
                                                    _vm.columnsTableUnits,
                                                  scroll: {
                                                    x: "calc(700px + 50%)"
                                                  },
                                                  paginationcustom: false,
                                                  defaultPagination: true,
                                                  loading:
                                                    _vm.loadingTableUnits,
                                                  selectedRowKeys:
                                                    _vm.selectedRowKeysUnits,
                                                  handleInput:
                                                    _vm.handleInputUnits,
                                                  handleSearchSelectTable:
                                                    _vm.handleSearchSelectTableUnits,
                                                  handleSelect:
                                                    _vm.handleSelectTableUnit,
                                                  onSelectChange:
                                                    _vm.onSelectChangeUnit
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { span: 24, align: "end" }
                                    },
                                    [
                                      _c(
                                        "a-tag",
                                        {
                                          staticStyle: { "font-size": "13px" },
                                          attrs: { color: "grey" }
                                        },
                                        [
                                          _vm._v(
                                            " Total Found : " +
                                              _vm._s(_vm.dataListUnits.length) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "mt-2 mb-2",
                                      staticStyle: {
                                        display: "flex",
                                        justifycontent: "flex-end"
                                      },
                                      attrs: {
                                        span: 12,
                                        xs: 24,
                                        s: 24,
                                        md: 24,
                                        lg: 12
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "1rem"
                                          },
                                          attrs: { type: "primary" },
                                          on: { click: _vm.handleAddRowUnits }
                                        },
                                        [_vm._v(" + Add Row ")]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "1rem"
                                          },
                                          attrs: { type: "danger" },
                                          on: {
                                            click: _vm.showConfirmationUnits
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              "vertical-align": "0"
                                            },
                                            attrs: { type: "delete" }
                                          }),
                                          _vm._v(" Delete Row ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-3", attrs: { span: 24 } },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "a-tabs",
                                        { attrs: { type: "card" } },
                                        [
                                          _c(
                                            "a-tab-pane",
                                            {
                                              key: "Products",
                                              staticClass: "bordertabs p-4",
                                              attrs: { tab: "Products" }
                                            },
                                            [
                                              _c("TableCustom", {
                                                attrs: {
                                                  idtable: "table1",
                                                  dataSource:
                                                    _vm.dataListProducts,
                                                  columns:
                                                    _vm.columnsTableProducts,
                                                  scroll: {
                                                    x: "calc(700px + 50%)"
                                                  },
                                                  paginationcustom: false,
                                                  defaultPagination: true,
                                                  loading:
                                                    _vm.loadingTableProducts,
                                                  selectedRowKeys:
                                                    _vm.selectedRowKeysProducts,
                                                  handleInput:
                                                    _vm.handleInputProducts,
                                                  onSelectChange:
                                                    _vm.onSelectChangeProduct,
                                                  handleSearchSelectTable:
                                                    _vm.handleSearchSelectTableProducts,
                                                  handleSelect:
                                                    _vm.handleSelectTableProduct
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { span: 24, align: "end" }
                                    },
                                    [
                                      _c(
                                        "a-tag",
                                        {
                                          staticStyle: { "font-size": "13px" },
                                          attrs: { color: "grey" }
                                        },
                                        [
                                          _vm._v(
                                            " Total Found : " +
                                              _vm._s(
                                                _vm.dataListProducts.length
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "mt-2 mb-2",
                                      staticStyle: {
                                        display: "flex",
                                        justifycontent: "flex-end"
                                      },
                                      attrs: {
                                        span: 12,
                                        xs: 24,
                                        s: 24,
                                        md: 24,
                                        lg: 12
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "1rem"
                                          },
                                          attrs: { type: "primary" },
                                          on: {
                                            click: _vm.handleAddRowProducts
                                          }
                                        },
                                        [_vm._v(" + Add Row ")]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "1rem"
                                          },
                                          attrs: { type: "danger" },
                                          on: {
                                            click: _vm.showConfirmationProducts
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              "vertical-align": "0"
                                            },
                                            attrs: { type: "delete" }
                                          }),
                                          _vm._v(" Delete Row ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end"
                              },
                              attrs: { span: 24 }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { type: "danger" }
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.save()
                                    }
                                  }
                                },
                                [_vm._v("Save")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }