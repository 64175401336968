














































































































































































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import moment from "moment";
import { changeCurrencytoNumeric } from "@/validator/globalvalidator";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import {
  DataListAddress,
  DataListEmployeeAssignment,
} from "@/models/interface/assets.interface";
import { assetsServices } from "@/services/assets.service";
import { productService } from "@/services/product.service";
import { settingsServices } from "@/services/settings.service";
import { logisticServices } from "@/services/logistic.service";
import {
  DataWorkOrderSparePart,
  DataWorkOrderUnit,
} from "@/models/interface/logistic.interface";
export default Vue.extend({
  data() {
    return {
      formatCurrencytoNumber: changeCurrencytoNumeric,
      form: this.$form.createForm(this, { name: "currency" }),
      datalistCustomerName: [] as DataListEmployeeAssignment[],
      datalistCustomerAddress: [] as DataListAddress[],
      loadingDatalistCustomerName: false as boolean,
      loadingDatalistCustomerAddress: false as boolean,
      datalistCustomerMechanic: [] as DataListEmployeeAssignment[],
      loadingDatalistCustomerMechanic: false as boolean,
      datalistAsset: [] as any[],
      loadingDatalistAsset: false as boolean,
      isFormSubmitted: false as boolean,
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      totalDataUnits: 0 as number,
      dataListUnits: [] as any[],
      listDeletedUnits: [] as any[],
      selectedRowKeysUnits: [] as number[],
      loadingTableUnits: false as boolean,
      totalDataProducts: 0 as number,
      dataListProducts: [] as any[],
      listDeletedProducts: [] as any[],
      selectedRowKeysProducts: [] as number[],
      dataListUnitCodeProducts: [] as any[],
      loadingTableProducts: false as boolean,
      formRules: {
        no: {
          label: "No",
          name: "no",
          placeholder: "Insert No",
          decorator: [
            "no",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        woDate: {
          label: "WO Date",
          name: "woDate",
          placeholder: "Insert WO Date",
          decorator: [
            "woDate",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        customerName: {
          label: "Customer Name",
          name: "customerName",
          placeholder: "Insert Customer Name",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        customerAddress: {
          label: "Customer Address",
          name: "customerAddress",
          placeholder: "Insert Customer Address",
          decorator: [
            "customerAddress",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        description: {
          label: "Description",
          name: "description",
          placeholder: "Insert Description",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        picName: {
          label: "PIC Name",
          name: "picName",
          placeholder: "Insert PIC Name",
          decorator: [
            "picName",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        mechanicName: {
          label: "Mechanic Name",
          name: "mechanicName",
          placeholder: "Insert Mechanic Name",
          decorator: [
            "mechanicName",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        scheduleOrder: {
          label: "Schedule Order",
          name: "scheduleOrder",
          placeholder: "Insert scheduleOrder",
          decorator: [
            "scheduleOrder",
            {
              rules: [
                {
                  required: true,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
      },
      columnsTableUnits: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 100,
          responsiveColSelect: [
            {
              name: "unitCode",
              placeholder: "Unit Code",
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as any[],
            },
            // {
            //   name: "category",
            //   placeholder: "Category",
            //   style: "width: 100%;",
            //   disabled: "",
            //   value: "id",
            //   options: [] as any[],
            // },
          ],
          responsiveColInput: [
            {
              name: "specification",
              placeholder: "specification",
              style: "width: 100%;",
              disabled: "",
            },
          ],
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 200,
          scopedSlots: { customRender: "unitCode" },
        },
        {
          title: "Serial Number",
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 200,
          scopedSlots: { customRender: "inNull" },
        },
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Capacity",
          dataIndex: "capacity",
          key: "capacity",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Specification",
          dataIndex: "specification",
          key: "specification",
          width: 200,
          scopedSlots: { customRender: "specification" },
        },
        {
          title: "Location",
          dataIndex: "location",
          key: "location",
          width: 300,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
      columnsTableProducts: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 100,
          responsiveColSelect: [
            {
              name: "unitCode",
              placeholder: "Unit Code",
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as any[],
            },
            {
              name: "productCode",
              placeholder: "Product Code",
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as any[],
            },
            {
              name: "productName",
              placeholder: "Product Name",
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as any[],
            },
            {
              name: "partNumber",
              placeholder: "partNumber",
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as any[],
            },
            {
              name: "uom",
              placeholder: "Uom",
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as any[],
            },
            {
              name: "location",
              placeholder: "Location",
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as any[],
            },
          ],
          responsiveColInput: [
            {
              name: "notes",
              placeholder: "notes",
              style: "width: 100%;",
              disabled: "",
            },
            {
              name: "qtyWo",
              placeholder: "Qty Wo",
              style: "width: 100%;",
              disabled: "",
            },
          ],
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 200,
          scopedSlots: { customRender: "unitCode" },
        },
        {
          title: "Product Code",
          dataIndex: "productCode",
          key: "productCode",
          width: 300,
          scopedSlots: { customRender: "productCode" },
        },
        {
          title: "Product Name",
          dataIndex: "productName",
          key: "productName",
          width: 300,
          scopedSlots: { customRender: "productName" },
        },
        {
          title: "Part Number",
          dataIndex: "partNumber",
          key: "partNumber",
          width: 200,
          scopedSlots: { customRender: "partNumber" },
        },
        {
          title: "UOM",
          dataIndex: "uom",
          key: "uom",
          width: 200,
          scopedSlots: { customRender: "uom" },
        },
        {
          title: "Location",
          dataIndex: "location",
          key: "location",
          width: 200,
          scopedSlots: { customRender: "location" },
        },
        {
          title: "Qty WO",
          dataIndex: "qtyWo",
          key: "qtyWo",
          width: 200,
          scopedSlots: { customRender: "qtyWo" },
        },
        {
          title: "Qty Available",
          dataIndex: "qtyAvailable",
          key: "qtyAvailable",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Notes",
          dataIndex: "notes",
          key: "notes",
          width: 200,
          scopedSlots: { customRender: "notes" },
        },
      ] as any,
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  created() {
    this.getListContact("");
    this.getListContactMechanic("");
    this.getListAsset("");
    this.getListOfProductCode("");
    this.getListOfProductName("");
    this.getListOfUom("");
  },
  methods: {
    moment,
    getListOfInventoryLine(key, productId, uomId, warehouseId, finalColumn) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (warehouseId) {
        params.search = `product.secureId~*${productId}*_AND_uom.secureId~*${uomId}*_AND_warehouseLocation.secureId~*${warehouseId}*`;
      } else {
        params.search = `product.secureId~*${productId}*_AND_uom.secureId~*${uomId}*`;
      }
      logisticServices.listOfInventory(params).then((res: any) => {
        if (finalColumn === "uom") {
          res.data.map(
            (dataObject, index) => (
              (dataObject.key = index),
              ((dataObject.name = dataObject.warehouseLocationName),
              (dataObject.id = dataObject.warehouseLocationId))
            )
          );
          const findColumnProduct = this.columnsTableProducts.find(
            (column) => column.responsiveColSelect
          )?.responsiveColSelect;
          findColumnProduct[5].options = res.data;
        } else {
          this.dataListProducts[key].qtyAvailable = res.data[0].available;
        }
      });
    },
    getListOfUom(value) {
      let params = {
        limit: 10,
        page: 0,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (value) params.search = `search=unit~*${value}*`;
      return settingsServices.listOfMasterUom(params, "").then((res: any) => {
        res.data.map(
          (dataObject, index) => (
            (dataObject.key = index), (dataObject.name = dataObject.unit)
          )
        );
        const findColumnProduct = this.columnsTableProducts.find(
          (column) => column.responsiveColSelect
        )?.responsiveColSelect;
        findColumnProduct[4].options = res.data;
      });
    },
    getListOfProductCode(value): void {
      let params = {
        limit: 10,
        page: 0,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (value) {
        params.search = `code~*${value}*`;
      }
      productService.listProduct(params).then((res: any) => {
        res.data.map(
          (dataObject, index) => (
            (dataObject.key = index,dataObject.nameUse = dataObject.name,dataObject.name = dataObject.code)
          )
        );
        const findColumnProduct = this.columnsTableProducts.find(
          (column) => column.responsiveColSelect
        )?.responsiveColSelect;
        findColumnProduct[1].options = res.data
      });
    },
    getListOfProductName(value): void {
      let params = {
        limit: 10,
        page: 0,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (value) {
        params.search = `name~*${value}*`;
      }
      productService.listProduct(params).then((res: any) => {
        res.data.map((dataObject, index) => (dataObject.key = index,dataObject.nameUse = dataObject.name));
        const findColumnProduct = this.columnsTableProducts.find(
          (column) => column.responsiveColSelect
        )?.responsiveColSelect;
        findColumnProduct[2].options = res.data
      });
    },
    getDetailProduct(id , key) {
      productService.detailProduct(id).then((res: any) => {
        const dataArr = [] as any[];
        const unique = [
          ...new Set(res.productSuppliers.map((item) => item.partNumber)),
        ];
        if (unique.length > 0) {
          unique.forEach((item, index) => {
            const obj = {
              key: index,
              name: item,
              id: item,
            };
            dataArr.push(obj);
          });
          const findColumnProduct = this.columnsTableProducts.find(
            (column) => column.responsiveColSelect
          )?.responsiveColSelect;
          findColumnProduct[3].options = dataArr;
        }
      });
    },
    onSelectChangeUnit(selectedRowKeysUnit) {
      this.selectedRowKeysUnits = selectedRowKeysUnit;
    },
    onSelectChangeProduct(selectedRowKeysProduct) {
      this.selectedRowKeysProducts = selectedRowKeysProduct;
    },
    handleSelectTableUnit(value, key, col, recordOptions): void {
      this.dataListUnits[key] = {
        ...this.dataListUnits[key],
        [col]: value,
      };
      this.dataListUnits = this.dataListUnits.slice();
      if (col === "unitCode") {
        this.dataListProducts.forEach((item) => {
          item.unitCode = "";
        });
        const findColumnProduct = this.columnsTableProducts.find(
          (column) => column.responsiveColSelect
        )?.responsiveColSelect;
        const listUnitCodeProduct = [] as any[];
        if (this.dataListUnits.length > 0) {
          const unique = [
            ...new Set(this.dataListUnits.map((item) => item.unitCode)),
          ];
          unique.forEach((item, index) => {
            const obj = {
              key: index,
              name: item,
              id: item,
            };
            listUnitCodeProduct.push(obj);
          });
        }
        findColumnProduct[0].options = listUnitCodeProduct;
        this.dataListUnits[key] = {
          ...this.dataListUnits[key],
          serialNumber: recordOptions.find((data) => data.unitCode === value)
            .serialNumber,
          id: recordOptions.find((data) => data.unitCode === value).idUse,
          capacity: recordOptions.find((data) => data.unitCode === value)
            .capacity,
          category: recordOptions.find((data) => data.unitCode === value)
            .assetCategory.id,
          location: `${
            recordOptions.find((data) => data.unitCode === value).assetLocation
              .warehouse.branchWarehouse.name
          },${
            recordOptions.find((data) => data.unitCode === value).assetLocation
              .warehouse.name
          },${
            recordOptions.find((data) => data.unitCode === value).assetLocation
              .name
          }`,
        };
      }
    },
    handleSelectTableProduct(value, key, col, recordOptions): void {
      this.dataListProducts[key] = {
        ...this.dataListProducts[key],
        [col]: value,
      };
      if (col === "productCode") {
        this.dataListProducts[key].location = "";
        this.getDetailProduct(value , key);
        this.dataListProducts[key] = {
          ...this.dataListProducts[key],
          partNumber: null,
          productName: recordOptions.find(item => item.id === value).nameUse,
          id: value,
        };
        if (
          this.dataListProducts[key].productCode &&
          this.dataListProducts[key].uom
        )
          this.getListOfInventoryLine(
            key,
            this.dataListProducts[key].productCode,
            this.dataListProducts[key].uom,
            "",
            "uom"
          );
      }
      if (col === "productName") {
        this.dataListProducts[key].location = "";
        this.getDetailProduct(value , key);
        this.dataListProducts[key] = {
          ...this.dataListProducts[key],
          partNumber: null,
          productName: recordOptions.find(item => item.id === value).name,
          productCode: recordOptions.find(item => item.id === value).code,
          id: recordOptions.find(item => item.id === value).id,
        };
        if (
          this.dataListProducts[key].productCode &&
          this.dataListProducts[key].uom
        )
          this.getListOfInventoryLine(
            key,
            this.dataListProducts[key].productCode,
            this.dataListProducts[key].uom,
            "",
            "uom"
          );
      }
      if (col === "uom") {
        if (
          this.dataListProducts[key].productCode &&
          this.dataListProducts[key].uom
        ) {
          this.dataListProducts[key].location = "";
          this.getListOfInventoryLine(
            key,
            this.dataListProducts[key].productCode,
            this.dataListProducts[key].uom,
            "",
            "uom"
          );
        }
      }

      if (col === "location") {
        if (
          this.dataListProducts[key].productCode &&
          this.dataListProducts[key].uom &&
          this.dataListProducts[key].location
        ) {
          this.dataListProducts.forEach((item) => {
            item.qtyAvailable = "";
          });
          this.getListOfInventoryLine(
            key,
            this.dataListProducts[key].productCode,
            this.dataListProducts[key].uom,
            this.dataListProducts[key].location,
            "location"
          );
        }
      }
      this.dataListProducts = this.dataListProducts.slice();
    },
    getListAsset(value) {
      let params = {
        limit: 10,
        page: 0,
      } as RequestQueryParamsModel;
      if (value) {
        params.search = `unitCode~*${value}*`;
      }
      return assetsServices.listMasterAsset(params).then((res: any) => {
        const findColumn = this.columnsTableUnits.find(
          (column) => column.responsiveColSelect
        )?.responsiveColSelect;
        res.data.map(
          (dataObject, index) => (
            (dataObject.key = index),
            (dataObject.name = dataObject.unitCode),
            (dataObject.idUse = dataObject.id),
            (dataObject.id = dataObject.unitCode)
          )
        );
        findColumn[0].options = res.data;
      });
    },
    changeContactAddress(value): void {
      this.form.setFieldsValue({
        picName: "",
      });
      setTimeout(() => {
        this.form.setFieldsValue({
          picName: this.datalistCustomerAddress.find(
            (item) => item.address == value
          )?.picContactNumber,
        });
      }, 400);
    },
    changeContact(value) {
      this.loadingDatalistCustomerAddress = true;
      this.datalistCustomerAddress = [];
      this.form.setFieldsValue({
        customerAddress: "",
        picName: "",
      });
      this.datalistCustomerName
        .find((item) => item.id == value)
        ?.addressDataList.forEach((item) => {
          this.datalistCustomerAddress.push(item);
          this.loadingDatalistCustomerAddress = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getListContact(value) {
      let params = {
        limit: 10,
        page: 0,
      } as RequestQueryParamsModel;
      if (value) {
        params.search = `customer~true_AND_active~true_AND_firstName~*${value}*`;
      } else {
        params.search = `customer~true_AND_active~true`;
      }
      return contactServices.listContactData(params).then((res: any) => {
        this.datalistCustomerName = res.data;
      });
    },
    getListContactMechanic(value) {
      let params = {
        limit: 10,
        page: 0,
      } as RequestQueryParamsModel;
      if (value) {
        params.search = `employeeData.position~SPV MEKANIK_AND_active~true_AND_firstName~*${value}*`;
      } else {
        params.search = `employeeData.position~SPV MEKANIK_AND_active~true`;
      }
      return contactServices.listContactData(params).then((res: any) => {
        this.datalistCustomerMechanic = res.data;
      });
    },
    showConfirmationUnits() {
      if (this.selectedRowKeysUnits.length > 0) {
        this.$confirm({
          title: `Do you want to delete these items?`,
          content: `Total: ${this.selectedRowKeysUnits.length} items will be deleted.`,
          onOk: () => {
            this.handleDeleteRowUnits();
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select at least one row to delete",
        });
      }
    },
    showConfirmationProducts() {
      if (this.selectedRowKeysProducts.length > 0) {
        this.$confirm({
          title: `Do you want to delete these items?`,
          content: `Total: ${this.selectedRowKeysProducts.length} items will be deleted.`,
          onOk: () => {
            this.handleDeleteRowProducts();
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select at least one row to delete",
        });
      }
    },
    handleInputUnits(
      value,
      key,
      objectColInput,
      objectColInputName: string,
      onEvt: string
    ) {
      let formatedVal: string | number = value;
      this.dataListUnits[key][objectColInput.name] = formatedVal;
      this.dataListUnits = this.dataListUnits.slice();
    },
    handleInputProducts(
      value,
      key,
      objectColInput,
      objectColInputName: string,
      onEvt: string
    ) {
      let formatedVal: string | number = value;
      this.dataListProducts[key][objectColInput.name] = formatedVal;
      this.dataListProducts = this.dataListProducts.slice();
    },
    handleDeleteRowUnits() {
      this.dataListUnits = this.dataListUnits.filter((data) => {
        return !this.selectedRowKeysUnits.includes(data.key);
      });
      this.dataListUnits.map((data, index) => (data.key = index));
      this.dataListUnits = this.dataListUnits.slice();
      this.selectedRowKeysUnits = [];
      this.dataListUnits.forEach((element, index) => {
        element.no = index + 1;
      });
      const findColumnProduct = this.columnsTableProducts.find(
        (column) => column.responsiveColSelect
      )?.responsiveColSelect;
      this.dataListProducts.forEach((item) => {
        item.unitCode = "";
      });
      const listUnitCodeProduct = [] as any[];
      if (this.dataListUnits.length > 0) {
        this.dataListUnits.forEach((item, index) => {
          const obj = {
            key: index,
            name: item.unitCode,
            id: item.unitCode,
          };
          listUnitCodeProduct.push(obj);
        });
      }
      findColumnProduct[0].options = listUnitCodeProduct;
    },
    handleDeleteRowProducts() {
      this.dataListProducts = this.dataListProducts.filter((data) => {
        return !this.selectedRowKeysProducts.includes(data.key);
      });
      this.dataListProducts.map((data, index) => (data.key = index));
      this.dataListProducts = this.dataListProducts.slice();
      this.selectedRowKeysProducts = [];
      this.dataListProducts.forEach((element, index) => {
        element.no = index + 1;
      });
    },
    handleAddRowUnits(): void {
      this.dataListUnits = [
        ...this.dataListUnits,
        {
          no: `${this.dataListUnits.length + 1}.`,
          unitCode: null,
          id: null,
          key: this.dataListUnits.length,
          serialNumber: "",
          category: "",
          capacity: "",
          specification: "",
          location: "",
        },
      ];
    },
    // responsePageSizeChangeUnits(response: ResponsePagination): void {
    //   console.log(response);
    // },
    // responseCurrentPageChangeUnits(response: ResponsePagination): void {
    //   console.log(response);
    // },
    handleAddRowProducts(): void {
      this.dataListProducts = [
        ...this.dataListProducts,
        {
          no: `${this.dataListProducts.length + 1}.`,
          unitCode: null,
          id: null,
          key: this.dataListProducts.length,
          productCode: "",
          productName: "",
          partNumber: "",
          uom: "",
          qtyWo: "",
          qtyUomAvailable: "",
          notes: "",
        },
      ];
    },
    // responsePageSizeChangeProducts(response: ResponsePagination): void {
    //   console.log(response);
    // },
    // responseCurrentPageChangeProducts(response: ResponsePagination): void {
    //   console.log(response);
    // },
    handleSearchSelectTableUnits(value, key, colName) {
      switch (colName) {
        case "unitCode":
          this.getListAsset(value)
          break;
      }
    },
    handleSearchSelectTableProducts(value, key, colName) {
      switch (colName) {
        case "productCode":
          this.getListOfProductCode(value);
          break;
          case "productName":
          this.getListOfProductName(value);
          break;
      }
    },
    save(): void {
      const postUnits = [] as DataWorkOrderUnit[];
      const postSpareParts = [] as DataWorkOrderSparePart[];
      this.dataListUnits.forEach((item) => {
        const obj = {
          unitCode: item.unitCode,
          assetId: item.id,
          unitSpecification: item.specification,
        };
        postUnits.push(obj);
      });
      this.dataListProducts.forEach((item) => {
        const obj = {
          unitCode: item.unitCode,
          notes: item.notes,
          partNumber: item.partNumber,
          productId: item.productCode,
          productUomId: item.uom,
          productLocationId: item.location,
          qtyWorkOrder: item.qtyWo,
        };
        postSpareParts.push(obj);
      });
      this.form.validateFields((err, value) => {
        if (!err) {
          if (postUnits.length > 0 && postSpareParts.length > 0) {
            let validateUnitDuplicate = false as boolean;
            if (this.dataListUnits.length > 1) {
              const uniqueValues = new Set(
                this.dataListUnits.map((v) => v.unitCode)
              );
              if (uniqueValues.size < this.dataListUnits.length) {
                validateUnitDuplicate = true;
              }
            }
            if (!validateUnitDuplicate) {
              const payload = {
                customerAddress: value.customerAddress,
                customerId: value.customerName,
                description: value.description,
                mechanicId: value.mechanicName,
                picName: value.picName,
                scheduleOrder: moment(value.scheduleOrder).format(),
                units: postUnits,
                spareParts: postSpareParts,
                workOrderDate: moment(value.woDate).format(),
              };
              logisticServices.createWorkOrder(payload).then(() => {
                this.$router.push("/logistic/workorder/list");
                this.$message.success(Messages.CREATE_SUCCESS);
              });
            } else {
              this.$notification.error({
                message: "Table Units",
                description: "Unit Code in table units cannot duplicated !",
              });
            }
          } else {
            this.$notification.error({
              message: "Table Units And Table Products",
              description:
                "Table Units And Table Products at least have 1 data !",
            });
          }
        }
      });
    },
  },
});
